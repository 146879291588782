import React from "react";
import {
  WhoWeAreContentWrapper,
  WhoWeAreWrapper,
  WrapperItemsCard,
  StyledParagraph,
  StyledPreTitle,
  StyledText,
  StyledTitle,
  WrapperWhoButton,
  StyledWhoCta,
  StyledYellowBar,
} from "./style.jsx";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import { Link } from "gatsby";

export default ({ content, beeImage }) => {
  const isMobile = useCheckMobileScreen();
  return (
    <WhoWeAreWrapper>
      {!isMobile && (
        <StyledYellowBar />
      )}
      <WhoWeAreContentWrapper backgroundImage={beeImage.path}>
        <WrapperItemsCard>
          <StyledPreTitle dangerouslySetInnerHTML={{ __html: content.preTitle }} />
          <StyledParagraph>
            <StyledTitle dangerouslySetInnerHTML={{ __html: content.title }} />
            <StyledText dangerouslySetInnerHTML={{ __html: content.paragraph }} />
          </StyledParagraph>
          <WrapperWhoButton>
            <StyledWhoCta >
              <Link to="/chi-siamo">{content.ctaLabel}</Link>
            </StyledWhoCta>
          </WrapperWhoButton>
        </WrapperItemsCard>
      </WhoWeAreContentWrapper>
      <StyledYellowBar />
    </WhoWeAreWrapper>
  );
};
